@import url('https://fonts.googleapis.com/css?family=Karla:400,700&display=swap');

body {
  margin: 0;
  font-family: 'Karla', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}


/*
///
HomePage stuff
///
*/

.main_container {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: absolute;
}

.top_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 80px 10vw 20px 10vw;
}


.top_logo {
  width: 100px;
}

.top_txt {
  width: 100%;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.top_txt p {
  font-weight: bold;
  font-size: 2.25em;
  max-width: 100%;
  margin: 35px 0 10px 0;
}

.top_txt small {
  font-weight: bold;
  font-size: 1em;
}

.top_txt a {
  color: black;
}

.sociallinks {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  width: 100%;
  margin-top: 30px;
}

.sociallink {
  width: 24px;
  margin-right: 12px;
}

.sociallinks a {
  text-decoration: none;
}

.project_container {
  width: 100%;
  box-sizing: border-box;
  padding: 0px 20px 50px 20px;
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 50vh;
}

.project_container_loading {
  box-sizing: border-box;
  padding: 0px 10vw 20px 10vw;
  color: grey;
  min-height: 50vh;
}
.project_container_loading p {
  background-color: #f7fafa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
}

.project_tile {
    width: 100%;
    box-sizing: border-box;
  }

.project_tile {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 10px;
  margin-right: 0px;
}

.project_tile a {
  width: 100%;
  height: 0px;
  font-size: 0px;
  line-height: 0px;
}
.project_tile img {
  width: 100%;
}

.lastlink_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 80px 10vw 20px 10vw;
}
.lastlink_container a {
  font-weight: bold;
  color: black;
}

.leftswinger {
  align-items: flex-end;
  justify-content: flex-end;
}
.leftswinger:hover {
  cursor: pointer;
}

.footer_container {
  background-color: black;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 70px 10vw 70px 10vw;
  color:white;
}

.footer_logo {
  width: 50px;
  margin-bottom: 30px;
}


.footer_txt {
  width: 100%;
}
.footer_txt p {
  /* color:white; */
  margin: 0px;
}

.footer_txt a {
  color: white;
}

.footer_closer a, .footer_closer p {
  color: rgba(255, 255, 255, 0.35);
  margin: 0;
}

.footer_closer {
  background-color: black;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 50px 20vw 90px 20vw;
  border-top: 1px white dotted;
  color: rgba(255, 255, 255, 0.35);
  text-align: center;
}

.boldtxt {
  font-weight: bold;
  text-decoration: underline;
}


/* 
///
DetailPage stuff
///
 */
.detail_container {
  position: absolute;

}

.detailtop_container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 70px 10vw 20px 10vw;
}

.backarrow {
  position: -webkit-sticky;
  position: sticky;
  top:70px;
  box-sizing: border-box;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 30px;
  outline: none;
  z-index: 99;
}

.backarrow img {
  width: 76px;
  height: 10px;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.detailtop_txt {
  width: 100%;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: 0;
}

.detailtop_txt p {
  font-weight: normal;
  font-size: 1.25em;
  line-height: 1.4em;
  max-width: 100%;
  margin: 0;
}

a.full_link {
  font-weight: bold;
  font-size: 1.25em;
  line-height: 1.4em;
  max-width: 100%;
  margin: 0;
}


p.detailtop_title {
  font-weight: bold;
  margin: 40px 0 0 0;
}
p.detailtop_body {
  margin: 10px 0 16px 0;
}

small.detailtop_time,small.detailtop_labels,a.detailtop_link {
  font-weight: bold;
  font-size: 1em;
  line-height: 1.3em;
}

a.detailtop_link {
  line-height: 1.5em;
}

.detailtop_labels {
  margin:0 0 16px 0;
}

.detailtop_txt a {
  color: black;
  word-break: break-all;
}

.detailimgs {
  width: 100vw;
  /* min-height: 80vh; */
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  margin-top: 8vh;
  padding: 0 3vw 30px 3vw;
}

.detailimgs img:nth-child(1),.detailimgs img:nth-child(4),.detailimgs img:nth-child(5),.detailimgs img:nth-child(8),.detailimgs img:nth-child(9) {
  width: 100%;
  height: 100%;
  max-width: 100%;
  margin-bottom: 3vw;
}
.detailimgs img:nth-child(2),.detailimgs img:nth-child(3),.detailimgs img:nth-child(6),.detailimgs img:nth-child(7) {
  width: 100%;
  height: 100%;
  max-width: 100%;
  margin-bottom: 3vw;
}


.absolute {
  position: absolute;
  /* width: 100%; */
}



img.avatar_img {
  width: 180px;
  height: 180px;
  margin: 30px 0 0px 0;
}

.dotted {
  border-top: 1px grey dotted;
  padding: 30px 10vw 20px 10vw;
  margin-top: 50px;
}

.about_me {
  width: 100%;
  box-sizing: border-box;
  padding: 0px 0 50px 0;
}

.about_me p {
  font-size: 1.25em;
  line-height: 1.4em;
}

.about_me a {
  font-size: 1em;
  line-height: 1.4em;
  font-weight: bold;
  color: black;
}

.about_me small {
  font-size: 1em;
  line-height: 1.4em;
}

.linkbutton {
  background-color: #e8eaeb;
  max-width: 200px;
  margin-top: 30px;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 0px 2px 1px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 2px 1px 0px rgba(0,0,0,0.25);
  box-shadow: 0px 2px 1px 0px rgba(0,0,0,0.25);
}

.linkbutton a {
  text-decoration: none;
}



/*
///
QUERY stuff: ipad/small desktop
///
*/


@media only screen and (min-width: 700px) and (max-width: 1500px) {


  .top_container {
    padding: 110px 10vw 20px 10vw;
  }

  .top_txt p {
    max-width: 730px;
  }

  .project_container {
    padding: 0px 10vw 70px 10vw;
  }

  .project_tile {
    max-width: 25vw;
    margin-right: 12px;
  }

  .backarrow {
    padding-left: 10vw;
  }
  

  p.detailtop_body {
    margin: 10px 0 40px 0;
  }

  .detailtop_labels {
    margin:0 0 30px 0;
  }
  

  
  .detailtop_txt {
    max-width: 860px;
  }



  .detailimgs {
    width: 100vw;
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
    margin-top: 8vh;
    padding: 0 10vw 80px 10vw;
    /* max-width: calc(10vw+1050px); */
  }

 .detailimgs img:nth-child(1),.detailimgs img:nth-child(4),.detailimgs img:nth-child(5),.detailimgs img:nth-child(8),.detailimgs img:nth-child(9) {
    width: 48.2vw;
    max-width: 672px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .detailimgs img:nth-child(2),.detailimgs img:nth-child(3),.detailimgs img:nth-child(6),.detailimgs img:nth-child(7) {
    width: 24vw;
    max-width: 332px;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .footer_closer {
    background-color: black;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 50px 10vw 90px 10vw;
    border-top: 1px white dotted;
    color: rgba(255, 255, 255, 0.35);
    text-align: left;
  }

  .extraspace {
    margin-top: 0px;
  }
  img.avatar_img {
    width: 180px;
    height: 180px;
    margin: 30px 0 10px 0;
  }

}


/*
///
QUERY stuff: xxl desktop
///
*/

@media only screen and (min-width: 1500px) {


  .top_container {
    padding: 110px 10vw 20px 10vw;
  }
  .top_txt {
    width: 71%;
  }

  .top_txt p {
    max-width: 730px;
    margin: 10px 0 10px 40px;
  }
  .top_txt small {
    margin: 0px 0 0px 40px;
  }
  .sociallinks {
    width: 10%;
    margin-top: 24px;
  }

  .project_container {
    padding: 0px 14vw 70px 10vw;
  }

  .project_tile {
    max-width: 21.85vw;
    margin-right: 12px;
  }
  
  .detailtop_txt {
    max-width: 1260px;
  }


  .detailimgs {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
    margin-top: 8vh;
    padding: 0 14vw 120px 10vw;
    /* max-width: calc(10vw+1050px); */
  }

  .detailimgs img:nth-child(1),.detailimgs img:nth-child(4),.detailimgs img:nth-child(5),.detailimgs img:nth-child(8),.detailimgs img:nth-child(9) {
    width: 44.2vw;
    max-width: 900px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .detailimgs img:nth-child(2),.detailimgs img:nth-child(3),.detailimgs img:nth-child(6),.detailimgs img:nth-child(7) {
    width: 22vw;
    max-width: 448px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
}











/*
///
Animation stuff
///
*/

.pageSlider-appear.to-home,
.pageSlider-enter.to-home {
    opacity: 0;
    transform: translate(100px, 0);
    z-index: 1;
}
.pageSlider-appear-active.to-home,
.pageSlider-enter.pageSlider-enter-active.to-home {
  opacity: 1;
  transform: translate(0px, 0);
  transition: all 240ms ease-out 120ms;
}

.pageSlider-exit.to-home {
  opacity: 1;
  transform: translate(0px, 0);
}

.pageSlider-exit.pageSlider-exit-active.to-home {
    opacity: 0;
    transform: translate(100px, 0);
    transition: all 120ms ease-out;
}




.pageSlider-appear.home-away,
.pageSlider-enter.home-away {
    opacity: 0;
    transform: translate(-100px, 0);
    z-index: 1;
}
.pageSlider-appear-active.home-away,
.pageSlider-enter.pageSlider-enter-active.home-away {
  opacity: 1;
  transform: translate(0px, 0);
  transition: all 240ms ease-out 120ms;
}

.pageSlider-exit.home-away {
  opacity: 1;
  transform: translate(0px, 0);
}

.pageSlider-exit.pageSlider-exit-active.home-away {
    opacity: 0;
    transform: translate(-100px, 0);
    transition: all 120ms ease-out;
}


